

























import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import Utils from 'truemarket-modules/src/Utils'
import { AddressModel } from 'truemarket-modules/src/models/api'
import { AutocompleteResultModel } from 'truemarket-modules/src/models/api/google/AutocompleteResultModel'
import { Services } from 'truemarket-modules/src/services'
import AutoComplete from '@/components/Controls/AutoComplete.vue'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    AutoComplete
  }
})
export default class AddressDetails extends Vue {
  @Ref('observer') readonly form!: InstanceType<typeof ValidationObserver>

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  mounted () {
    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.form.flags.pristine

    if (tempValId && pristine) {
      this.$emit('resumed', tempValId)
      this.loadData(tempValId)
    }
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'AddressDetails' }
    ).then((response: any) => {
      if (!response.Data) return

      this.addressModel.UnitNumber = response.Data.Address.UnitNumber
      this.addressModel.StreetNumber = response.Data.Address.StreetNumber
      this.addressModel.StreetName = response.Data.Address.StreetName
      this.addressModel.Suburb = response.Data.Address.Suburb
      this.addressModel.State = response.Data.Address.State
      this.addressModel.Postcode = response.Data.Address.Postcode

      const formattedAddress = this.buildFormattedAddress()
      this.addressModel.FormattedAddress = formattedAddress
      this.propertyAddress = formattedAddress
    })
  }

  validate () {
    return this.form.validateWithInfo()
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  async submit () {
    if (this.addressModel.FormattedAddress === '') this.addressModel.StreetName = this.propertyAddress

    const data = {
      Address: this.addressModel
    }
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'AddressDetails',
      Data: JSON.stringify(data)
    }
    return await Services.API.ValuationForm.ProcessTempValuation(request).then(response => {
      return {
        data,
        response,
        success: true
      }
    }).catch((e) => {
      return {
        data,
        response: e,
        success: false
      }
    })
  }

  addressModel: AddressModel = {
    UnitNumber: '',
    StreetNumber: '',
    StreetName: '',
    Suburb: '',
    State: '',
    Postcode: '',
    FormattedAddress: '',
    FormattedAddressLines: []
  }

  propertyAddress = ''
  tempQuery = ''
  gmapsSession = Utils.GetGuid()
  suggestions: AutocompleteResultModel[] = []

  @Watch('propertyAddress')
  clearAddress () {
    this.addressModel.UnitNumber = ''
    this.addressModel.StreetNumber = ''
    this.addressModel.StreetName = ''
    this.addressModel.Suburb = ''
    this.addressModel.State = ''
    this.addressModel.Postcode = ''
    this.addressModel.FormattedAddress = ''
    this.addressModel.FormattedAddressLines = []
  }

  searchAddresses () {
    this.tempQuery = this.propertyAddress

    Services.API.Google.AddressAutocomplete(
      this.propertyAddress, 'country:au', this.gmapsSession
    ).then((ac) => {
      this.suggestions = ac.Predictions
    })
  }

  // eslint-disable-next-line
  resolveAddress (event: any) {
    this.propertyAddress = this.tempQuery

    Services.API.Google.GetPlaceDetails(event.value.PlaceId, this.gmapsSession).then((res) => {
      const place = res.Result

      this.addressModel.UnitNumber = place.AddressComponents.find((ac) => ac.Types.includes('subpremise'))?.LongName ?? ''
      this.addressModel.StreetNumber = place.AddressComponents.find((ac) => ac.Types.includes('street_number'))?.LongName ?? ''
      this.addressModel.StreetName = place.AddressComponents.find((ac) => ac.Types.includes('route'))?.LongName ?? ''
      this.addressModel.Suburb = place.AddressComponents.find((ac) => ac.Types.includes('locality'))?.LongName ?? ''
      this.addressModel.State = place.AddressComponents.find((ac) => ac.Types.includes('administrative_area_level_1'))?.LongName ?? ''
      this.addressModel.Postcode = place.AddressComponents.find((ac) => ac.Types.includes('postal_code'))?.LongName ?? ''

      const formattedAddress = this.buildFormattedAddress()
      this.addressModel.FormattedAddress = formattedAddress
      this.propertyAddress = formattedAddress
    })
  }

  buildFormattedAddress () {
    const formattedComponents = []

    if (this.addressModel.UnitNumber) {
      formattedComponents.push(this.addressModel.UnitNumber + '/' + this.addressModel.StreetNumber)
    } else if (this.addressModel.StreetNumber) {
      formattedComponents.push(this.addressModel.StreetNumber)
    }

    if (this.addressModel.StreetName) formattedComponents.push(this.addressModel.StreetName)
    if (this.addressModel.Suburb) formattedComponents.push(this.addressModel.Suburb)
    if (this.addressModel.Postcode) formattedComponents.push(this.addressModel.Postcode)

    return formattedComponents.join(' ')
  }
}
