








import { Component, Vue } from 'vue-property-decorator'

type Transition = 'right' | 'left' | 'none'
type AnyFunction = (...args: any[]) => any

@Component
export default class ValuationStep extends Vue {
  active = false
  transition: Transition = 'right'
  stepValidationHandler: AnyFunction = () => null
  stepSubmissionHandler: AnyFunction = () => null

  show (transition: Transition) {
    this.transition = transition
    this.active = true
  }

  hide (transition: Transition) {
    this.transition = transition
    this.active = false
  }

  submit () {
    return this.stepSubmissionHandler()
  }

  validate () {
    return this.stepValidationHandler()
  }

  validationHandler (handler: AnyFunction) {
    this.stepValidationHandler = handler
  }

  submissionHandler (handler: AnyFunction) {
    this.stepSubmissionHandler = handler
  }
}
