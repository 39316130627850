










import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueCalendar from 'primevue/calendar'

@Component({
  components: {
    PrimeVueCalendar
  }
})
export default class Calendar extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  @Prop({ required: false, default: 'date' })
  public view!: string

  onInput (newVal: Date) {
    if (this.view === 'month') this.$emit('input', (newVal.getMonth() + 1).toString())
    else if (this.view === 'year') this.$emit('input', newVal.getFullYear().toString())
    else this.$emit('input', newVal)
  }

  get dateFormat () {
    if (this.view === 'month') return 'mm/yy'
    if (this.view === 'year') return 'yy'
    return 'dd/mm/yy'
  }

  // regex = /^(3[01]|[12][0-9]|0[1-9])(\/|-)(1[0-2]|0[1-9])\2(19|20|21)\d{2}$/
}
