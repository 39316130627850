

































































import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Ref, Prop } from 'vue-property-decorator'
import InputText from '@/components/Controls/InputText.vue'
import Dropdown from '@/components/Controls/Dropdown.vue'
import { Services } from 'truemarket-modules/src/services'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    InputText,
    Dropdown
  }
})
export default class ContactDetails extends Vue {
  @Ref('observer') readonly form!: InstanceType<typeof ValidationObserver>

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  mounted () {
    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.form.flags.pristine

    if (tempValId && pristine) this.loadData(tempValId)
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'ContactDetails' }
    ).then((response: any) => {
      if (!response.Data) return

      this.firstName = response.Data.FirstName
      this.lastName = response.Data.LastName
      this.email = response.Data.Email
      this.phone = response.Data.Phone
      this.businessName = response.Data.BusinessName
      this.repFullName = response.Data.RepName
      this.repEmail = response.Data.RepEmail
      this.repPhone = response.Data.RepPhone
      this.repBusinessName = response.Data.RepBusinessName
      this.repType = response.Data.RepType
    })
  }

  validate () {
    return this.form.validateWithInfo()
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  async submit () {
    const data = {
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      Phone: this.phone,
      BusinessName: this.businessName,
      RepName: this.repFullName,
      RepEmail: this.repEmail,
      RepPhone: this.repPhone,
      RepBusinessName: this.repBusinessName,
      RepType: this.repType
    }
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'ContactDetails',
      Data: JSON.stringify(data)
    }
    return await Services.API.ValuationForm.ProcessTempValuation(request).then(response => {
      return {
        data,
        response,
        success: true
      }
    }).catch((e) => {
      return {
        data,
        response: e,
        success: false
      }
    })
  }

  get repDetailsRequired () {
    return this.repTypes.filter((v) => v.requireDetails)
      .map(v => v.value)
      .includes(this.repType)
  }

  repTypes = [
    { label: 'Accountant', value: 'Accountant', requireDetails: true },
    { label: 'Lawyer', value: 'Lawyer', requireDetails: true },
    { label: 'None', value: 'None' }
  ]

  firstName = ''
  lastName = ''
  email = ''
  phone = ''
  businessName = ''

  repType = ''
  repFullName = ''
  repEmail = ''
  repPhone = ''
  repBusinessName = ''
}
