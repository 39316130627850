









import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueInputText from 'primevue/inputtext'

@Component({
  components: {
    PrimeVueInputText
  }
})
export default class InputText extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }
}
