





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import type ValuationStep from '@/components/Controls/Valuation/ValuationStep.vue'

// eslint-disable-next-line
export type StepResult = { stepName: string; data: any; response: any }

@Component
export default class ValuationStepper extends Vue {
  @Prop({ required: true })
  steps!: string[]

  @Prop({ required: true })
  stepKeys!: string[]

  @Prop({ required: false, default: 0 })
  activeStep!: number

  currentStep = 0
  stepsHeight = 0
  stepsHeightDuration = 0
  ro: ResizeObserver | null = null
  roElement: Element | null = null

  @Watch('activeStep')
  setCurrentStep () {
    if (this.isValidIndex(this.activeStep)) this.currentStep = this.activeStep
  }

  @Watch('currentStep')
  showCurrentStep (newStep: number, oldStep: number) {
    this.unsubResize()
    const transition = newStep > oldStep ? 'right' : 'left'
    this.stepComponentAtIndex(oldStep).hide(transition)
    const comp = this.stepComponentAtIndex(newStep)
    comp.show(transition)
    this.setStepsHeight(comp.$el, true)
    this.subResize(comp.$el)
  }

  setStepsHeight (el: Element, transition = false) {
    Vue.nextTick(() => {
      this.stepsHeight = el.clientHeight
      this.stepsHeightDuration = transition ? 0.6 : 0
    })
  }

  subResize (el: Element) {
    this.roElement = el
    this.ro = new ResizeObserver(() => this.setStepsHeight(el, true))
    this.ro.observe(this.roElement)
  }

  unsubResize () {
    if (this.ro && this.roElement) this.ro.unobserve(this.roElement)
  }

  mounted () {
    const comp = this.stepComponentAtIndex(this.currentStep)
    comp.show('none')
    this.setStepsHeight(comp.$el, false)
    this.subResize(comp.$el)
  }

  beforeDestroy () {
    this.unsubResize()
  }

  stepComponentAtIndex (index: number) {
    return this.$children[index] as InstanceType<typeof ValuationStep>
  }

  previous () {
    const newStep = this.currentStep - 1
    if (!this.isValidIndex(newStep)) return
    this.currentStep = newStep
  }

  async next () {
    // TODO blockUI + spinner
    const success = await this.processCurrentStep()
    // TODO unblockUI
    if (!success) return

    const newStep = this.currentStep + 1
    if (!this.isValidIndex(newStep)) return
    this.currentStep = newStep
  }

  async processCurrentStep () {
    const comp = this.stepComponentAtIndex(this.currentStep)
    const validationResult = await comp.validate()
    const submissionResult = await comp.submit()
    if (
      (validationResult && !validationResult.isValid) ||
      !submissionResult.success) {
      return false
    }
    const stepName = this.stepKeys[this.currentStep]
    this.$emit('stepSubmitted', { stepName, ...submissionResult })
    return true
  }

  isValidIndex (index: number) {
    return index >= 0 && index < this.steps.length
  }

  get getStepsHeight () {
    return {
      '--steps-height': this.stepsHeight,
      '--steps-height-duration': this.stepsHeightDuration
    }
  }
}
