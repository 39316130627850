var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"step-heading"},[_c('h3',{staticClass:"title"},[_vm._v("Step 4: Your Details")]),_c('p',{staticClass:"description"},[_vm._v(" Please enter your contact details for report delivery and to ask any follow-up questions if required ")])]),_c('form',{staticClass:"step-field-form"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"contactFirstName","error":errors[0],"placeholder":"Your first name","label":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"contactLastName","error":errors[0],"placeholder":"Your last name","label":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"contactEmail","error":errors[0],"placeholder":"Your email address","label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"contactPhone","error":errors[0],"placeholder":"Your phone number","label":"Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Business Name (optional)","rules":"alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"contactBusinessName","error":errors[0],"placeholder":"Your business name","label":"Business Name (optional)"},model:{value:(_vm.businessName),callback:function ($$v) {_vm.businessName=$$v},expression:"businessName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Representative","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Dropdown',{attrs:{"fieldId":"representativeRepType","error":errors[0],"options":_vm.repTypes,"placeholder":"Select type","label":"Representative"},model:{value:(_vm.repType),callback:function ($$v) {_vm.repType=$$v},expression:"repType"}})]}}])}),(_vm.repDetailsRequired)?[_c('ValidationProvider',{attrs:{"name":"Representative Name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"representativeRepFullName","error":errors[0],"placeholder":"Representative full name","label":"Representative Name"},model:{value:(_vm.repFullName),callback:function ($$v) {_vm.repFullName=$$v},expression:"repFullName"}})]}}],null,false,1029170608)}),_c('ValidationProvider',{attrs:{"name":"Representative Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"representativeRepEmail","error":errors[0],"placeholder":"Representative email address","label":"Representative Email"},model:{value:(_vm.repEmail),callback:function ($$v) {_vm.repEmail=$$v},expression:"repEmail"}})]}}],null,false,274618453)}),_c('ValidationProvider',{attrs:{"name":"Representative Phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"representativeRepPhone","error":errors[0],"placeholder":"Representative phone number","label":"Representative Phone"},model:{value:(_vm.repPhone),callback:function ($$v) {_vm.repPhone=$$v},expression:"repPhone"}})]}}],null,false,3435590560)}),_c('ValidationProvider',{attrs:{"name":"Representative Business Name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"representativeRepBusinessName","error":errors[0],"placeholder":"Representative business name","label":"Representative Business Name"},model:{value:(_vm.repBusinessName),callback:function ($$v) {_vm.repBusinessName=$$v},expression:"repBusinessName"}})]}}],null,false,2435323267)})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }