



























import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Ref, Prop } from 'vue-property-decorator'
import FileUpload from '@/components/Controls/FileUpload.vue'
import InputText from '@/components/Controls/InputText.vue'
import { Services } from 'truemarket-modules/src/services'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    FileUpload,
    InputText
  }
})
export default class PhotosAndDocuments extends Vue {
  @Ref('observer') readonly form!: InstanceType<typeof ValidationObserver>
  @Ref('photos') readonly photos!: InstanceType<typeof FileUpload>
  @Ref('buildingPlans') readonly buildingPlans!: InstanceType<typeof FileUpload>
  @Ref('leaseDocs') readonly leaseDocs!: InstanceType<typeof FileUpload>
  @Ref('tenancySchedule') readonly tenancySchedule!: InstanceType<typeof FileUpload>

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  mounted () {
    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.form.flags.pristine

    if (tempValId && pristine) this.loadData(tempValId)
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'PhotosAndDocuments' }
    ).then((response: any) => {
      if (!response.Data) return

      this.photos.resumeFiles(response.Data.Photos)
      this.buildingPlans.resumeFiles(response.Data.BuildingPlans)
      this.leaseDocs.resumeFiles(response.Data.LeaseDocs)
      this.tenancySchedule.resumeFiles(response.Data.TenancySchedule)
      this.listingLink = response.Data.ListingLink
    })
  }

  async validate () {
    const photosValid = this.photos.validate()
    const buildingPlansValid = this.buildingPlans.validate()
    const leaseDocsValid = this.leaseDocs.validate()
    const tenancyScheduleValid = this.tenancySchedule.validate()
    const formValid = await this.form.validateWithInfo().then((res) => {
      return res.isValid
    })

    return {
      isValid: photosValid && buildingPlansValid &&
        leaseDocsValid && tenancyScheduleValid && formValid
    }
  }

  timeout: ReturnType<typeof setTimeout> | undefined

  filesUpdate () {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      await this.submit()
    }, 6000)
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  async submit () {
    const data = {
      Photos: this.photos.getFileIds(),
      BuildingPlans: this.buildingPlans.getFileIds(),
      LeaseDocs: this.leaseDocs.getFileIds(),
      TenancySchedule: this.tenancySchedule.getFileIds(),
      ListingLink: this.listingLink
    }
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'PhotosAndDocuments',
      Data: JSON.stringify(data)
    }
    return await Services.API.ValuationForm.ProcessTempValuation(request).then(response => {
      return {
        data,
        response,
        success: true
      }
    }).catch((e) => {
      return {
        data,
        response: e,
        success: false
      }
    })
  }

  listingLink = ''
}
