















import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueAutoComplete from 'primevue/autocomplete'

@Component({
  components: {
    PrimeVueAutoComplete
  }
})
export default class AutoComplete extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public fieldKey!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  @Prop({ required: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public suggestions!: any[]

  @Prop({ required: true })
  public complete!: () => void

  @Prop({ required: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public itemSelect!: (event: any) => void

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }
}
