import { render, staticRenderFns } from "./PropertyDetails.vue?vue&type=template&id=05432892&scoped=true&"
import script from "./PropertyDetails.vue?vue&type=script&lang=ts&"
export * from "./PropertyDetails.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyDetails.vue?vue&type=style&index=0&id=05432892&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05432892",
  null
  
)

export default component.exports