import { render, staticRenderFns } from "./PhotosAndDocuments.vue?vue&type=template&id=7aaf7c0b&scoped=true&"
import script from "./PhotosAndDocuments.vue?vue&type=script&lang=ts&"
export * from "./PhotosAndDocuments.vue?vue&type=script&lang=ts&"
import style0 from "./PhotosAndDocuments.vue?vue&type=style&index=0&id=7aaf7c0b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aaf7c0b",
  null
  
)

export default component.exports