











import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import { Services } from 'truemarket-modules/src/services'
import { Config } from 'truemarket-modules/src/environment'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css'
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css'

import { FileStatus } from 'filepond'
import vueFilePond, { VueFilePondComponent } from 'vue-filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginGetFile from 'filepond-plugin-get-file'

const FilePond = vueFilePond(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile
)

const docTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

const imageTypes = [
  'image/*',
  'image/heic'
]

const videoTypes = [
  'video/*'
]

@Component({
  components: {
    FilePond
  }
})
export default class FileUpload extends Vue {
  @Ref('pond') readonly pond!: VueFilePondComponent

  files: any[] = []

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false, default: 5 })
  public maxFiles!: number

  @Prop({ required: false, default: 0 })
  public requiredFiles!: number

  @Prop({ required: false, default: 3 })
  public maxParallelUploads!: number

  @Prop({ required: false, default: true })
  public acceptImages!: boolean

  @Prop({ required: false, default: true })
  public acceptVideos!: boolean

  @Prop({ required: false, default: true })
  public acceptDocs!: boolean

  resumeFiles (fileIds: string[]) {
    fileIds.forEach((fileId) => {
      this.pond.addFile(fileId, {
        type: 'limbo'
      })
    })
  }

  getFileIds () {
    return this.pond.getFiles()
      .filter((file) => file.status === FileStatus.PROCESSING_COMPLETE)
      .map((file) => file.serverId)
  }

  validate () {
    this.error = ''

    const files = this.pond.getFiles()
    const isFinished = files.filter(
      (file) => file.status !== FileStatus.PROCESSING_COMPLETE).length === 0

    if (!isFinished) {
      this.error =
        'Please wait for all files to finish uploading, check that all files are valid and try again'
      return false
    }

    if (files.length < this.requiredFiles) {
      this.error = `Please upload at least ${this.requiredFiles} file(s)`
      return false
    }

    return true
  }

  handleUpdateFiles (update: any) {
    this.error = ''
    this.$emit('updateFiles', update)
  }

  processUpload (
    fieldName: any, file: any, metadata: any,
    load: any, error: any, progress: any,
    abort: any, transfer: any, options: any) {
    // fieldName is the name of the input field
    // file is the actual file object to send
    const formData = new FormData()
    formData.append('File', file, file.name)

    const credential = Services.CredentialManager.GetToken()
    const path = `${Config.API.Base}/api/files/upload_global`
    const request = new XMLHttpRequest()
    request.open('POST', path)
    request.setRequestHeader('Authorization', `Bearer ${credential?.Token}`)

    // Should call the progress method to update the progress to 100% before calling load
    // Setting computable to false switches the loading indicator to infinite mode
    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total)
    }

    // Should call the load method when done and pass the returned server file id
    // this server file id is then used later on when reverting or restoring a file
    // so your server knows which file to return without exposing that info to the client
    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        // the load method accepts either a string (id) or an object
        const response = JSON.parse(request.responseText)
        load(response.FileId)
      } else {
        // Can call the error method if something is wrong, should exit after
        error('Could not process upload')
      }
    }

    request.send(formData)

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        request.abort()

        // Let FilePond know the request has been cancelled
        abort()
      }
    }
  }

  revertUpload (uniqueFileId: any, load: any, error: any) {
    // Should remove the earlier created temp file here
    // ...

    // Can call the error method if something is wrong, should exit after
    // error('Could not revert upload')

    // Should call the load method when done, no parameters required
    load()
  }

  restoreUpload (
    uniqueFileId: any, load: any, error: any,
    progress: any, abort: any, headers: any) {
    const credential = Services.CredentialManager.GetToken()
    const path = `${Config.API.Base}/api/files/${uniqueFileId}/download_global`
    const request = new XMLHttpRequest()
    request.open('GET', path)
    request.setRequestHeader('Authorization', `Bearer ${credential?.Token}`)
    request.responseType = 'blob'

    request.onprogress = function (pe) {
      if (pe.lengthComputable) {
        // Should call the progress method to update the progress to 100% before calling load
        // (computable, loadedSize, totalSize)
        progress(true, pe.loaded, pe.total)
      }
    }
    request.onload = function (pe) {
      if (request.status >= 200 && request.status < 300) {
        // Should call the load method with a file object when done
        load(request.response)
      } else {
        // Can call the error method if something is wrong, should exit after
        error('Could not restore upload')
      }
    }
    request.onreadystatechange = () => {
      // 2 = HEADERS_RECEIVED
      if (request.readyState === 2) {
        // Can call the header method to supply FilePond with early response header string
        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
        const headersString = request.getAllResponseHeaders()
        headers(headersString)
      }
    }
    request.send()

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // User tapped abort, cancel our ongoing actions here
        // Let FilePond know the request has been cancelled
        abort()
      }
    }
  }

  server: any = {
    process: this.processUpload, // process the file before uploading
    revert: this.revertUpload, // delete file from temp server
    restore: this.restoreUpload, // restore temp file from server
    load: null, // load restore final file from server
    fetch: null, // upload files from a remote url
    remove: null // remove local files from server
  }

  get idleLabel () {
    const acceptedFiles = [
      this.acceptImages ? 'Images' : null,
      this.acceptVideos ? 'Videos' : null,
      this.acceptDocs ? 'Docs or PDFs' : null
    ].filter(n => n).join('/')

    return `Drag and drop files (${acceptedFiles}) to here to upload.`
  }

  get fileTypes () {
    return [
      ...(this.acceptImages ? imageTypes : []),
      ...(this.acceptVideos ? videoTypes : []),
      ...(this.acceptDocs ? docTypes : [])
    ].join(', ')
  }

  error = ''
}
