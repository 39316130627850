










import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueDropdown from 'primevue/dropdown'

export type DropdownOption = {
  label: string;
  value: string;
  [key: string]: boolean | string | undefined;
}

@Component({
  components: {
    PrimeVueDropdown
  }
})
export default class Dropdown extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: false, default: 'label' })
  public fieldKey!: string

  @Prop({ required: false, default: 'value' })
  public fieldValue!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  @Prop({ required: true })
  public options!: DropdownOption[]

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }
}
