





































import { Component, Vue } from 'vue-property-decorator'
import ValuationStepper, { StepResult } from '@/components/Controls/Valuation/ValuationStepper.vue'
import ValuationStep from '@/components/Controls/Valuation/ValuationStep.vue'
import AddressDetails from '@/components/Controls/Valuation/AddressDetails.vue'
import AssessmentDetails from '@/components/Controls/Valuation/AssessmentDetails.vue'
import PropertyClassification from '@/components/Controls/Valuation/PropertyClassification.vue'
import ContactDetails from '@/components/Controls/Valuation/ContactDetails.vue'
import PropertyDetails from '@/components/Controls/Valuation/PropertyDetails.vue'
import PhotosAndDocuments from '@/components/Controls/Valuation/PhotosAndDocuments.vue'
import Payment from '@/components/Controls/Valuation/Payment.vue'

@Component({
  components: {
    ValuationStepper,
    ValuationStep,
    AddressDetails,
    AssessmentDetails,
    PropertyClassification,
    ContactDetails,
    PropertyDetails,
    PhotosAndDocuments,
    Payment
  }
})
export default class New extends Vue {
  public steps: string[] = [
    'Address', 'Assessment Details', 'Property Classification',
    'Your Details', 'Property Details', 'Photos & Documents', 'Payment'
  ]

  public stepKeys: string[] = [
    'AddressDetails', 'AssessmentDetails', 'PropertyClassification',
    'ContactDetails', 'PropertyDetails', 'PhotosAndDocuments', 'Payment'
  ]

  resumedHandler (id: string) {
    this.tempValuationId = id
  }

  stepSubmitted (e: StepResult) {
    if (e.stepName === 'AddressDetails') {
      this.tempValuationId = e.response.TempValuationId
    }

    if (e.stepName === 'AssessmentDetails') {
      this.purpose = e.data.Purpose
    }

    if (e.stepName === 'PropertyClassification') {
      this.propertyType = e.data.PropertyType
      this.propertyClassification = e.data.PropertyClassification
    }
  }

  tempValuationId: string | null = null

  purpose: string | null = null

  propertyType: string | null = null
  propertyClassification: string | null = null
}
