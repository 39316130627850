























import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import Dropdown, { DropdownOption } from '@/components/Controls/Dropdown.vue'
import { Services } from 'truemarket-modules/src/services'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    Dropdown
  }
})
export default class PropertyClassification extends Vue {
  @Ref('observer') readonly form!: InstanceType<typeof ValidationObserver>

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  mounted () {
    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyType).then((vals) => {
      this.propertyTypes = vals.map((v) => ({ label: v.Label, value: v.ValueId }))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertySubType).then((vals) => {
      this.allPropertyClassifications = vals.map((v) => ({ label: v.Label, value: v.ValueId, typeId: v.ParentId }))
    })

    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.form.flags.pristine

    if (tempValId && pristine) this.loadData(tempValId)
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'PropertyClassification' }
    ).then((response: any) => {
      if (!response.Data) return

      this.propertyType = response.Data.PropertyType

      return response
    }).then((response: any) => {
      if (!response) return

      this.propertyClassification = response.Data.PropertyClassification
    })
  }

  validate () {
    return this.form.validateWithInfo()
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  async submit () {
    const data = {
      PropertyType: this.propertyType,
      PropertyClassification: this.propertyClassification
    }
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'PropertyClassification',
      Data: JSON.stringify(data)
    }
    return await Services.API.ValuationForm.ProcessTempValuation(request).then(response => {
      return {
        data,
        response,
        success: true
      }
    }).catch((e) => {
      return {
        data,
        response: e,
        success: false
      }
    })
  }

  propertyType = ''
  propertyTypes: DropdownOption[] = []
  propertyClassification = ''
  allPropertyClassifications: DropdownOption[] = []

  get propertyClassifications (): DropdownOption[] {
    return this.allPropertyClassifications.filter((v) => v.typeId === this.propertyType)
  }

  @Watch('propertyType')
  typeOnChange () {
    this.propertyClassification = ''
  }
}
