










import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueCheckbox from 'primevue/checkbox'

@Component({
  components: {
    PrimeVueCheckbox
  }
})
export default class Checkbox extends Vue {
  @Prop({ required: true })
  public value!: boolean

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  onInput (newVal: boolean) {
    this.$emit('input', newVal)
  }
}
